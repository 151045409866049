import '../scss/main.scss'
import React from 'react'
import Layout from './Layout'

class App extends React.Component {
  render () {
    return (
      <Layout />
    )
  }
}

export default App
