import React from 'react'
import { connect } from 'react-redux'
import Spinner from '../Spinner'
import Img from 'react-image'
//import CardSource from '../../presentational/Card/Source'
import { selectActiveNarrative } from '../../../selectors'
// memunculkan semua data event berdasarkan filter dan narative
function NarrativeCard ({ narrative }) {
  // no display if no narrative
  const { steps, current } = narrative

//add new script from wawan
// const isImgUrl = /\.(jpg|png)$/
//   let thumbnail = steps[current].sources[0].thumbnail

//   if (!thumbnail || thumbnail === '' || !thumbnail.match(isImgUrl)) {
//     // default to first image in paths, null if no images
//     const imgs = source.paths.filter(p => p.match(isImgUrl))
//     thumbnail = imgs.length > 0 ? imgs[0] : null
//   }
//   const fallbackIcon = (
//     <i className='material-icons source-icon'>
//       {renderIconText(steps[current].sources[0].type)}
//     </i>
//   )
//end code from wawan
  if (steps[current]) {
    //console.log(narrative)
    return (
      <div className='narrative-info'>
        <div className='narrative-info-header'>
          <div className='count-container'>
            <div className='count'>
              {current + 1}/{steps.length}
            </div>
          </div>
          <div>
            <h3>{narrative.id}</h3>
          </div>
        </div>

        {/* <i className='material-icons left'>location_on</i> */}
        {/* {_renderActions(current, steps)} */}
        
        
        <div className='narrative-info-desc'>
          {/* add new code from wawan */}
        {steps[0].sources.map(source => (
          <Img
          className='source-icon'
          src={source.thumbnail}
          loader={<Spinner small />}
          width={125}
          height={125}
        />
        ))}
        {/* end code from wawan */}
          <p>{narrative.desc}</p>
        </div>
      </div>
    )
  } else {
    return null
  }
}

function mapStateToProps (state) {
  return {
    narrative: selectActiveNarrative(state)
  }
}
export default connect(mapStateToProps)(NarrativeCard)
