// Sensible defaults for generating a basic card layout
// based on the example Timemap datasheet.
import React from 'react'
import Spinner from '../components/presentational/Spinner'
import Img from 'react-image'
import { empty } from 'ramda'
const basic = ({ event }) => {
  
  let link =''
  let titleLink='Source'
  let titleImage='Image'
  let thumb ={}
  let i = 0
  const isNull = Object.keys(event.sources).length;
  if(event.sources[0]!==null) {
    link = event.sources.map(
      arrpaths=>(arrpaths)?
        (arrpaths.paths.map(
            (path,index) => (path)?(
        
      //  <div style={{ 'pointer-events': 'visible' }}>  
      //  {/* <a href={path} target="_blank">{path}</a> */}
       <button style={{ 'pointer-events': 'visible','cursor': 'pointer','border-radius': '12px' }} type="button" class="button button--small button--secondary " onClick={() => handleClick(path)}>{'Source '}{i+=1}</button>
      //  </div>
         
    ):'')
    
    ):''
      )
      
    }
    
    if (event.sources[0]!==null){
   thumb = event.sources.map(image =>(
      <Img 
        className='gallery'
        src={image.thumbnail}
        loader={<Spinner small />}
        width={100}
        height={100} />
    ))
     }
   if(event.sources[0]!==null) {
     titleLink=''
     titleImage=''
  }
  console.log(event)

   return [
    [
      {
        kind: 'date',
        title: 'Incident Date',
        value: event.datetime || event.date || ``
      },
      { 
        kind: 'text',
        title: 'Location',
        value: event.location || `—`
      }
    ],
    [{ kind: 'line-break', times: 0.4 }],
    [
      
      { 
        kind: 'text',
        title: 'Summary EN',
        value: event.description || ``,
        scaleFont: 1.1
      }
    ],
    [{ kind: 'line-break', times: 0.4 }],
    [
      
      { 
        kind: 'text',
        title: 'Summary ID',
        value: event.description_id || ``,
        scaleFont: 1.1
      }
    ],
    [{ kind: 'line-break', times: 0.4 }],
    [
      { 
        kind: 'text',
        title: titleLink || ``,
        value: link || ``,
        scaleFont: 1.1
      }
    ],
    
    [{ kind: 'line-break', times: 0.4 }],
    [
      { 
        kind: 'text',
        title: titleImage || ``,
        value: thumb || ``,
        scaleFont: 1.1 
      }
    ],
    // [{ kind: 'line-break', times: 0.4 }],
    // [
    //   {
    //     kind: 'text',
    //     title: 'link',
    //     value: event.sources[0].description || ``,
    //     scaleFont: 1.1
    //   }
    // ]
  ]
}
const handleClick=(varlink)=> {
  const newWindow = window.open(varlink, '_blank', 'noopener,noreferrer')
}
export default {
  basic
}
